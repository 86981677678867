import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';

import { LocalStorageKeys } from '../../../../constants/localStorageKeys';

import CropImageModal from '../../../../components/modal/CropImageModal';
import { LoadingSpinner } from '../../../../components/spinner/LoadingSpinner';
import { COLOR_WHITE, GRAY_COLORS } from '../../../../constants/colors';
import Button from '../../../../components/buttons/Button';
import Flex from '../../../../components/utils/flex/Flex';
import SupportedAiProviderLogo from '../../../../components/SupportedProviderLogo/SupportedAiProviderLogo';
import { useStore } from '../../../../hooks/useStore';
import useUploadAndCropPicture from '../../../../hooks/useUploadAndCropPicture';
import { getAssetsKey, uploadDocumentSecurely } from '../../../../utils/documentsS3';

type UploadModelLogoProps = {
  setLogoPicture: (value: string) => void;
};

const UploadModelLogo = ({ setLogoPicture }: UploadModelLogoProps) => {
  const {
    appState: { s3DocumentsApi },
    modelStore: { currentOrganizationModel },
  } = useStore();

  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const { imageToCrop, isCropImageModalOpen, uploadLoading, previewImage, onUploadImage, onCropDone, closeCropModal } =
    useUploadAndCropPicture();

  const uploadFile = async (file: any) => {
    const tenant = localStorage.getItem(LocalStorageKeys.tenant) || 'avm';
    const fileKey = getAssetsKey(tenant, 'organizationModelLogos', currentOrganizationModel.modelId);

    const writeUrl = await s3DocumentsApi.generateAssetsWriteUrl(fileKey);

    const status = await uploadDocumentSecurely(writeUrl, file);

    if (status === 200) {
      const readUrl = await s3DocumentsApi.generateAssetsReadUrl(fileKey);

      setLogoPicture(readUrl.signedUrl);
    }
  };

  const handleSave = async (file: any) => {
    onCropDone(file);

    try {
      setLoading(true);

      await uploadFile(file);
    } catch (e) {
      console.log('e ------------------->> ', e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <UploadPictureContainer>
      <Flex sx={{ alignItems: 'center' }}>
        <ImageContainer>
          {previewImage ? (
            <img
              src={previewImage}
              alt={'previewImage'}
              style={{ height: '64px', width: '64px', borderRadius: '6px' }}
            />
          ) : currentOrganizationModel.logoPicture ? (
            <img
              src={currentOrganizationModel.logoPicture}
              alt={'previewImage'}
              style={{ height: '64px', width: '64px', borderRadius: '6px' }}
            />
          ) : (
            <SupportedAiProviderLogo provider={currentOrganizationModel.providerName} size={64} />
          )}
        </ImageContainer>

        <Flex sx={{ marginLeft: '16px', flexDirection: 'column' }}>
          <Button
            variant={'outlined'}
            startIcon={<UploadOutlinedIcon />}
            sx={{
              width: 'fit-content',
              height: '40px !important',
              marginRight: '8px',
              borderColor: `${GRAY_COLORS.GRAY_2} !important`,
              color: `${GRAY_COLORS.GRAY_9} !important`,
            }}
            onClick={() => inputRef?.current?.click()}
          >
            Replace
          </Button>

          <Typography variant={'subtitle2'} sx={{ color: GRAY_COLORS.GRAY_6, marginTop: '12px' }}>
            *.jpg, *.jpeg, *.png up to 10MB at least 200px by 200px
          </Typography>
        </Flex>
      </Flex>

      <Box sx={{ backgroundColor: 'paleturquoise' }}>
        <MyInput
          type="file"
          ref={inputRef}
          id={'upload-photo'}
          name={'upload-photo'}
          onChange={onUploadImage}
          onClick={(event: any) => {
            event.target.value = null;
          }}
          accept={'image/png, image/jpg, image/jpeg, image/heic'}
        />
      </Box>

      <CropImageModal
        isOpen={isCropImageModalOpen}
        onClose={closeCropModal}
        image={imageToCrop}
        onSave={handleSave}
        loading={uploadLoading}
      />
      {(loading || uploadLoading) && (
        <EditIconContainer>
          <LoadingSpinner color={COLOR_WHITE} />
        </EditIconContainer>
      )}
    </UploadPictureContainer>
  );
};

const ImageContainer = styled(Box)`
  display: flex;
  width: 70px;
  height: 70px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--new-greyscale-grey-2, #e0e2e9);
`;

const MyInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const EditIconContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const UploadPictureContainer = styled(Box)`
  position: relative;
`;

export default observer(UploadModelLogo);
