import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material';
import { RadioButtonUncheckedRounded, TripOrigin } from '@mui/icons-material';
import { observer } from 'mobx-react';

import { COLOR_PRIMARY, COLOR_TABLE_HEADER, GRAY_COLORS } from '../../constants/colors';

import { OrganizationModel } from '../../models/OrganizationModel';
import Flex from '../utils/flex/Flex';
import { getTextOverflowEllipsisStyles } from '../typography/utils';
import { useStore } from '../../hooks/useStore';
import { PrimarySwitch } from '../Switch/PrimarySwitch';
import SupportedAiProviderLogo from '../SupportedProviderLogo/SupportedAiProviderLogo';
import CopyTypography from '../CopyTypography/CopyTypography';

const OptionsButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

interface ModelTableRowProps {
  model: OrganizationModel;
  index: number;
  setIsDirtyState: React.Dispatch<React.SetStateAction<boolean>>;
  openAddDefaultModelModal: (headerTextValue: string) => void;
  openEditScreen: () => void;
}

const ModelTableRow = ({
  model,
  index,
  setIsDirtyState,
  openAddDefaultModelModal,
  openEditScreen,
}: ModelTableRowProps) => {
  const [open, setOpen] = useState(false);
  const [displayShowMoreButton, setDisplayShowMoreButton] = useState(false);
  const [showMoreActive, setShowMoreActive] = useState(false);
  const [isPrimaryRowHovered, setIsPrimaryRowHovered] = useState(false);
  const [isCollapsedRowHovered, setIsCollapsedRowHovered] = useState(false);

  const {
    localizationStore: { i18next: i18n },
    modelStore: { defaultOrganizationModel, updateOrganizationModel, setCurrentOrganizationModel },
  } = useStore();

  const { id } = model;

  const isDefaultModel = defaultOrganizationModel.id === id;

  const ref = createRef<HTMLSpanElement>();

  const isEllipsisActive = (element: HTMLElement | null) => {
    if (!element) {
      return false;
    }

    return element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight;
  };

  const handleIsMarkedAsDefaultChange = async (isMarkedAsDefault: boolean) => {
    setIsDirtyState(true);

    if (isDefaultModel && !isMarkedAsDefault) {
      await updateOrganizationModel({ ...model, isDefault: false });
      openAddDefaultModelModal('modelsPage.modal.header.needAtLeastOneDefaultModel');
      return;
    }

    if (!isDefaultModel && isMarkedAsDefault) {
      await updateOrganizationModel({ ...defaultOrganizationModel, isDefault: false });
    }

    await updateOrganizationModel({ ...model, isDefault: isMarkedAsDefault });
  };

  const handleIsActiveChange = async (isActive: boolean) => {
    setIsDirtyState(true);

    if (isDefaultModel && !isActive) {
      openAddDefaultModelModal('modelsPage.modal.header.cantDisableDefaultModel');
      return;
    }

    await updateOrganizationModel({ ...model, isActive });
  };

  useEffect(() => {
    setDisplayShowMoreButton(isEllipsisActive(ref.current));
  }, [ref]);

  const handleToggleButtonClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <>
      <TableRow
        onMouseEnter={() => setIsPrimaryRowHovered(true)}
        onMouseLeave={() => setIsPrimaryRowHovered(false)}
        sx={[
          (isPrimaryRowHovered || isCollapsedRowHovered) && { backgroundColor: COLOR_TABLE_HEADER },
          { height: '82px', verticalAlign: 'top' },
        ]}
      >
        <StyledTableCell>
          <Flex sx={{ height: '100%', alignItems: 'center' }}>
            <OptionsButtonContainer onClick={handleToggleButtonClick}>
              <IconButton size="small">{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
            </OptionsButtonContainer>
            <Typography sx={{ fontWeight: '600' }}>{model.displayName}</Typography>
          </Flex>
        </StyledTableCell>

        <StyledTableCell>
          <SupportedAiProviderLogo provider={model.providerName} size={24} />
        </StyledTableCell>

        <StyledTableCell>
          <Box>
            <InlineTypography sx={showMoreActive ? {} : { ...getTextOverflowEllipsisStyles(3) }} ref={ref}>
              {model.description}{' '}
            </InlineTypography>
            {(displayShowMoreButton || showMoreActive) && (
              <InlineTypography
                style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer', display: 'inline-block' }}
                onClick={() => {
                  setShowMoreActive(!showMoreActive);
                }}
              >
                {showMoreActive
                  ? i18n.t('adminPortal.tabs.models.readLess')
                  : i18n.t('adminPortal.tabs.models.readMore')}
              </InlineTypography>
            )}
          </Box>
        </StyledTableCell>

        <StyledTableCell>
          <FormControl sx={{ marginTop: 'auto' }}>
            <FormControlLabel
              onChange={(_event, checked) => {
                handleIsMarkedAsDefaultChange(checked);
              }}
              control={
                <Checkbox
                  checked={isDefaultModel}
                  icon={<RadioButtonUncheckedRounded />}
                  checkedIcon={<TripOrigin />}
                />
              }
              sx={isDefaultModel ? { '& > *': { color: `${COLOR_PRIMARY} !important` } } : {}}
              label={''}
            />
          </FormControl>
        </StyledTableCell>

        <StyledTableCell>
          <PrimarySwitch checked={model.isActive} onChange={event => handleIsActiveChange(event.target.checked)} />
        </StyledTableCell>

        <StyledTableCell align={'right'}>
          <Flex sx={{ justifyContent: 'flex-end' }}>
            <IconButton
              onClick={() => {
                setCurrentOrganizationModel(model);
                openEditScreen();
              }}
            >
              <ModeEditOutlineOutlinedIcon sx={{ width: '24px', height: '24px', color: GRAY_COLORS.GRAY_5 }} />
            </IconButton>

            <IconButton onClick={() => {}}>
              <DeleteOutlineOutlinedIcon sx={{ width: '24px', height: '24px', color: GRAY_COLORS.GRAY_5 }} />
            </IconButton>
          </Flex>
        </StyledTableCell>
      </TableRow>

      <TableRow
        onMouseEnter={() => setIsCollapsedRowHovered(true)}
        onMouseLeave={() => setIsCollapsedRowHovered(false)}
        sx={{ backgroundColor: isPrimaryRowHovered || isCollapsedRowHovered ? COLOR_TABLE_HEADER : 'inherit' }}
      >
        <StyledTableCell sx={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" sx={{ borderLeft: 'none', padding: '0 20px 0 10px' }}>
            <Flex sx={{ padding: '8px 0' }}>
              {model.apiEndpoint && <CopyTypography label={'API Endpoint'} value={model.apiEndpoint} />}
              {model.apiKey && <CopyTypography label={'API Key'} value={model.apiKey} sx={{ marginLeft: '40px' }} />}
            </Flex>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

const InlineTypography = styled(Typography)`
  display: inline;
`;

const StyledTableCell = styled(TableCell)`
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  background: inherit !important;
  padding-top: 14px;
  padding-bottom: 12px;
`;

export default observer(ModelTableRow);
