import Flex from '../../../../components/utils/flex/Flex';
import { observer } from 'mobx-react';
import { Headline3 } from '../../../../components/typography/Headlines';
import { useStore } from '../../../../hooks/useStore';
import { PrimarySwitch } from '../../../../components/Switch/PrimarySwitch';
import React, { ChangeEvent, useState } from 'react';
import Button from '../../../../components/buttons/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextInput from '../../../../components/inputs/TextInput';
import UploadLogo from './UploadLogo';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { RadioButtonUncheckedRounded, TripOrigin } from '@mui/icons-material';
import { COLOR_PRIMARY } from '../../../../constants/colors';

type EditModelProps = {
  onClose: () => void;
  openAddDefaultModelModal: (headerTextValue: string) => void;
};

const EditModel = ({ openAddDefaultModelModal, onClose }: EditModelProps) => {
  const {
    modelStore: { currentOrganizationModel, updateOrganizationModel, defaultOrganizationModel },
    localizationStore: { i18next: i18n },
  } = useStore();

  const isDefaultModel = defaultOrganizationModel.id === currentOrganizationModel.id;

  const [tempOrganizationModel, setTempOrganizationModel] = useState(currentOrganizationModel);
  const [isDirtyState, setIsDirtyState] = useState(false);
  const [loading, setLoading] = useState(false);

  const { displayName, isActive, description, apiKey, isDefault, apiEndpoint } = tempOrganizationModel;

  const handleIsActiveChange = (isActive: boolean) => {
    setTempOrganizationModel(currentValue => {
      return {
        ...currentValue,
        isActive,
      };
    });

    setIsDirtyState(true);
  };

  const logoPictureChange = (value: string) => {
    setTempOrganizationModel(currentValue => {
      return {
        ...currentValue,
        logoPicture: value,
      };
    });
    setIsDirtyState(true);
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = event.target;
    setIsDirtyState(true);

    setTempOrganizationModel(currentValue => {
      return {
        ...currentValue,
        [name]: value,
      };
    });
  };

  const handleSaveChanges = async () => {
    setLoading(true);

    if (isDefaultModel && (!tempOrganizationModel.isDefault || !isActive)) {
      if (!isActive) {
        setTempOrganizationModel(currentValue => {
          return {
            ...currentValue,
            isDefault: false,
          };
        });
      }

      openAddDefaultModelModal('modelsPage.modal.header.needAtLeastOneDefaultModel');
      setLoading(false);
      return;
    }

    if (!isDefault && tempOrganizationModel.isDefault) {
      await updateOrganizationModel({ ...defaultOrganizationModel, isDefault: false });
    }

    await updateOrganizationModel(tempOrganizationModel);
    setLoading(false);
    onClose();
  };

  const handleIsMarkedAsDefaultChange = async (isMarkedAsDefault: boolean) => {
    setIsDirtyState(true);
    setTempOrganizationModel(currentValue => {
      return {
        ...currentValue,
        isDefault: isMarkedAsDefault,
      };
    });
  };

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Flex>
        <Flex sx={{ alignItems: 'center', flex: 1 }}>
          <Headline3>{currentOrganizationModel.displayName}</Headline3>
          <PrimarySwitch checked={isActive} onChange={event => handleIsActiveChange(event.target.checked)} />
        </Flex>
        <Box>
          <Button disabled={!isDirtyState} onClick={handleSaveChanges} loading={loading}>
            {i18n.t('common.saveChanges.action')}
          </Button>
        </Box>
      </Flex>

      <Typography variant={'subtitle1'} sx={{ fontWeight: 500, marginBottom: '4px', marginTop: '24px' }}>
        Display Name
      </Typography>

      <TextInput
        id="displayName"
        placeholder={i18n.t('prompts.textField.title.placeHolder')}
        name={'displayName'}
        value={displayName}
        // error={i18n.t(formErrors.title)}
        onChange={onChange}
        variant={'outlined'}
        type={'text'}
        testId={'add-prompt-title'}
        sx={{
          marginBottom: '16px',
          '& > .MuiInputBase-root': { height: '48px !important' },
          '& .MuiInputLabel-root.Mui-error': { top: '-4px' },
          '& .MuiInputLabel-root': { top: '-4px' },
          '& .MuiInputLabel-shrink': { top: 0 },
        }}
      />

      <Typography variant={'subtitle1'} sx={{ fontWeight: 500, marginBottom: '4px', marginTop: '16px' }}>
        Description
      </Typography>

      <TextInput
        id="description"
        placeholder={i18n.t('prompts.textField.title.placeHolder')}
        name={'description'}
        value={description}
        // error={i18n.t(formErrors.title)}
        onChange={onChange}
        variant={'outlined'}
        type={'text'}
        testId={'add-prompt-title'}
        rows={4}
      />

      <Typography variant={'subtitle1'} sx={{ fontWeight: 500, marginBottom: '4px', marginTop: '100px' }}>
        Source Logo
      </Typography>

      <UploadLogo setLogoPicture={logoPictureChange} />

      <Typography variant={'subtitle1'} sx={{ fontWeight: 500, marginBottom: '4px', marginTop: '16px' }}>
        API Endpoint
      </Typography>

      <TextInput
        id="apiEndpoint"
        placeholder={i18n.t('prompts.textField.title.placeHolder')}
        name={'apiEndpoint'}
        value={apiEndpoint || ''}
        // error={i18n.t(formErrors.title)}
        onChange={onChange}
        variant={'outlined'}
        type={'text'}
        testId={'add-prompt-title'}
        sx={{
          marginBottom: '16px',
          '& > .MuiInputBase-root': { height: '48px !important' },
          '& .MuiInputLabel-root.Mui-error': { top: '-4px' },
          '& .MuiInputLabel-root': { top: '-4px' },
          '& .MuiInputLabel-shrink': { top: 0 },
        }}
      />

      <Typography variant={'subtitle1'} sx={{ fontWeight: 500, marginBottom: '4px', marginTop: '24px' }}>
        API Key
      </Typography>

      <TextInput
        id="apiKey"
        placeholder={i18n.t('prompts.textField.title.placeHolder')}
        name={'apiKey'}
        value={apiKey}
        // error={i18n.t(formErrors.title)}
        onChange={onChange}
        variant={'outlined'}
        type={'text'}
        testId={'add-prompt-title'}
        sx={{
          marginBottom: '16px',
          '& > .MuiInputBase-root': { height: '48px !important' },
          '& .MuiInputLabel-root.Mui-error': { top: '-4px' },
          '& .MuiInputLabel-root': { top: '-4px' },
          '& .MuiInputLabel-shrink': { top: 0 },
        }}
      />

      <FormControl sx={{ marginTop: 'auto' }}>
        <FormControlLabel
          onChange={(_event, checked) => {
            handleIsMarkedAsDefaultChange(checked);
          }}
          control={<Checkbox checked={isDefault} icon={<RadioButtonUncheckedRounded />} checkedIcon={<TripOrigin />} />}
          sx={isDefaultModel ? { '& > *': { color: `${COLOR_PRIMARY} !important` } } : {}}
          label={i18n.t('models.radioButton.defaultModel')}
        />
      </FormControl>
    </Flex>
  );
};

export default observer(EditModel);
